import { PageTemplate } from './PageTemplate'
import { SettingCompanyComponent } from '../components/AppMenuBar/SettingCompanyComponent'

const ShowSettingCompanyPage = () => {
    return (
        <PageTemplate
            type="customer" headerPage="companysetting" headerElement="header"
            leftPaneComponent={null} mainComponent={SettingCompanyComponent()} buttonComponent={null} >
        </PageTemplate>
    );
}
export { ShowSettingCompanyPage }