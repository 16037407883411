import { createSignal, createMemo, createResource, For, Show, Suspense, Switch as SolidSwitch, Match } from "solid-js";
import { createStore, } from "solid-js/store";
import { SelectChangeEvent } from "@suid/material/Select";
import { Checkbox, RadioGroup, Radio, FormLabel, ListItemIcon, ListItemButton, ListItem, FormControlLabel, Switch, Chip, TextField, FormControl, InputLabel, Select, Menu, MenuItem, ListItemText, ToggleButtonGroup, ToggleButton, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, Typography, Button, IconButton, Stack, Card, Alert, Popover, Grid, Divider, Box } from "@suid/material";
import { Link, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@suid/material"
import { ThemeSwitcher } from "./AppBarToogleColorMode"
import { AvatarWithWfButtons, } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'

import { Tabs, Tab } from '../SmallComponents/SmallTabs';
import { TextDecreaseIcon, TextIncreaseIcon, SpellcheckIcon, ExecuteIcon, Looks1Icon, Looks2Icon, FormatUndoIcon, EditIcon, AddIcon, DeleteIcon, SaveIcon, LockIcon, CloseListIcon } from '../icons';

import { useNavigate, A } from "@solidjs/router";
import { AccountStatusChip } from '../SmallComponents/SmallChips';
import { ShowUserProfile } from '../SmallComponents/SmallUser';
import { IndustryList } from "../MediumComponents/IndustriesComponent";

import { RateValueForTable } from '../SmallComponents/SmallRateValue';
import { FieldTextValue } from "../SmallComponents/SmallFieldText";
import { CountrySelect } from '../SmallComponents/SmallFieldCountryCodeList'

import { formatDateByCountryLong, formatDateByCountry, formatTimeByCountry, isInvoiceDueDatePassed } from "../../../utils/datetimeflag"
import { isExtraSmall, isMedium, isLarge, isExtraLarge } from "../../../utils/sizeUtil"
import { getText, GetTextUI } from '../SmallComponents/Translate'
import { TxtEditor } from '../SmallComponents/SmallEditor';

import { CompanyFetchType } from "../../../types/companyDataTypes"

interface DialogHeaderProps {
    header: string;
}

const countryCode = "DK"

const apiUrl = import.meta.env.VITE_APP_API_URL;


const fetchUser = async (): Promise<CompanyFetchType> => {
    const response = await fetch(`${apiUrl}/security/company`, {
        method: "GET", credentials: "include", headers: { "Content-Type": "application/json" } as HeadersInit
    });
    if (!response.ok) {
        throw new Error('User response was not ok.');
    }
    const data = await response.json();
    return data;
}


const SettingCompanyComponent = () => {

    const checkSize = isExtraLarge()

    const [activeTab, setActiveTab] = createSignal<number>(0)

    const [open, setOpen] = createSignal(false);
    const handleClose = () => setOpen(false);

    const [state, setState] = createStore({

        companyLogo: 'http://',
        companyId: '',
        companyName: '',
        webUrl: '',
        legalCompanyNumber: '',
        billingEmail: '',
        countryCode2Chars: '',
        candidateView: 'no',
        customerView: 'no',
        brokerView: 'no',
        supplierView: 'no',
        companyType: '',
        companyStatus: '',
        verified: 'yes',
    });

    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);

    };

    const [company] = createResource<CompanyFetchType>(() => fetchUser());

    const navigate = useNavigate();
    const handleNavigateRoute = (name: string) => {
        navigate(name);
    }


    const [subMenuAnchorEl, setSubMenuAnchorEl] = createSignal<Element | null>(null);
    const openPopover = () => Boolean(anchorEl());
    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);
    const handleOpenPopover = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const [anchorMenuEl, setAnchorMenuEl] = createSignal<Element | null>(null);
    const openMenu = () => Boolean(anchorMenuEl());
    const handleMenuOpen = (event: { currentTarget: Element }) => {
        setAnchorMenuEl(event.currentTarget);
    }
    const handleMenuClose = () => {
        setAnchorMenuEl(null);
    };
    const handleSubMenuOpen = (event: { currentTarget: Element }) => {
        setSubMenuAnchorEl(event.currentTarget);
    }
    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
    };

    const [size, setSize] = createSignal({ dialogMargin: 2, dialogSpacing: 2, buttonSize: 200 })
    if (isExtraSmall())
        setSize({ dialogMargin: 1, dialogSpacing: 1, buttonSize: 40 });


    const [checked, setChecked] = createSignal(false);

    const onClickCustomer = () => {
        setState("customerView", state.customerView === "yes" ? "no" : "yes")
    }
    const onClickCandidate = () => {
        setState("candidateView", state.candidateView === "yes" ? "no" : "yes")
    }

    const callHover = () => {
        setState("candidateView", state.candidateView === "yes" ? "no" : "yes")
    }

    const [templateGroupId, setTemplateGroupId] = createSignal('interview');
    const [templateId, setTemplateId] = createSignal('planinterview');

    const txtClose = getText("default", "close")
    const txtAdd = getText("smallcomponent", "addtag")
    const txtHeaderBillingCustomer = getText("companysetting", "billingheadercustomer")
    const txtHeaderBillingCandidate = getText("companysetting", "billingheadercandidate")
    const txtHeaderViews = getText("onboard", "tabviews")
    const txtHeaderAddress = getText("contact", "address")
    const txtHeaderTest = getText("companysetting", "testheader")
    const txtHeaderMessageTemplates = getText("companysetting", "messagetemplates")
    const txtHeaderContractSetting = getText("companysetting", "contractsetting")
    const txtHeaderInvoices = getText("companysetting", "invoices")
    const txtHeaderGreylisted = getText("default", "greylisted")
    const txtHeaderUsers = getText("companysetting", "users")
    const txtTabDetails = getText("onboard", "tabdetails")
    const txtTabViews = getText("onboard", "tabviews")
    const txtTabUsers = getText("companysetting", "users")
    const txtTabGreyList = getText("companysetting", "tabgreylist")
    const txtTabTest = getText("companysetting", "tabtest")
    const txtTabMessage = getText("companysetting", "tabmessage")
    const txtTabContract = getText("companysetting", "tabcontract")
    const txtTabBilling = getText("companysetting", "tabbilling")

    const tabDetailsId = 0, tabViewsId = 1, tabUsersId = 2, tabGreylistId = 3, tabTestId = 4, tabMessageId = 5, tabContractId = 6, tabBillingId = 7
    const [hoveredRow, setHoveredRow] = createSignal(-1);

    //     //
    //     <Checkbox
    //     checked={checked()}
    //     onChange={(event, checked) => {
    //         setChecked(checked);
    //     }}
    //     inputProps={{ "aria-label": "controlled" }}
    // />

    return (
        <>
            <Card sx={{ margin: 1 }}>

                <Suspense fallback={
                    <Alert sx={{ px: 1, py: 1 }} severity="info">
                        <GetTextUI formName={"default"} label={"loading"} />.
                    </Alert>
                }>
                    <For each={company()?.company}>
                        {(item, idx) => (
                            <>
                                <Grid container   >

                                    <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                                        <Stack margin={1}>
                                            <Tabs activeTab={activeTab()} setActiveTab={setActiveTab}>

                                                <Tab>{txtTabDetails()} </Tab>
                                                <Tab>{txtTabViews()}  </Tab>
                                                <Tab>{txtTabUsers()}  </Tab>
                                                <Tab>{txtTabGreyList()}  </Tab>
                                                <Tab>{txtTabTest()}  </Tab>
                                                <Tab>{txtTabMessage()}  </Tab>
                                                <Tab>{txtTabContract()} </Tab>
                                                <Tab>{txtTabBilling()}  </Tab>


                                            </Tabs>
                                        </Stack>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2} >
                                        <Stack direction="row" justifyContent="right" alignContent="center" margin={1} spacing={1}>
                                            {/* Present Candidates account status */}
                                            <AccountStatusChip >{item.verified ?
                                                <GetTextUI formName={"candidatepage"} label={"accountverified"} /> :
                                                <GetTextUI formName={"candidatepage"} label={"accountnotverified"} />}
                                            </AccountStatusChip>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>

                                <Box sx={{ p: 2, display: 'flex' }}>

                                    <Grid container spacing={2}>
                                        <Show when={activeTab() === tabDetailsId}>
                                            <Grid item xs={12} sm={4} md={3} lg={2} xl={2}>
                                                <AvatarWithWfButtons src={item?.src} />
                                            </Grid>
                                            <Grid item xs={12} sm={8} md={9} lg={10} xl={5}>

                                                <Stack spacing={2}>
                                                    <Stack direction="row" spacing={1}>
                                                        <Typography variant="h4">{item.companyName}</Typography>

                                                    </Stack>
                                                    <Stack direction={(isExtraLarge() || isLarge()) ? "row" : "column"} spacing={2}>
                                                        <FieldTextValue
                                                            id="webPage" label={<GetTextUI formName={"contact"} label={"companywww"} /> as string} textValue={item.webUrl as string} width={230}
                                                            valueOnChange={value => setState("webUrl", value)}
                                                        />
                                                        <FieldTextValue
                                                            id="companyNumber" label={<GetTextUI formName={"contact"} label={"companynumber"} /> as string} textValue={item.legalCompanyNumber as string} width={230}
                                                            valueOnChange={value => setState("legalCompanyNumber", value)}
                                                        />
                                                        <FieldTextValue
                                                            id="companyName" label={<GetTextUI formName={"contact"} label={"companyname"} /> as string} textValue={item.companyName as string} width={230}
                                                            valueOnChange={value => setState("companyName", value)}
                                                        />
                                                    </Stack>
                                                    <CountrySelect defaultValue={item.countryCode2Chars as string} id="contryId" label={<GetTextUI formName={"contact"} label={"country"} /> as string} type="Country" valueOnChange={value => setState("countryCode2Chars", value)} />

                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Typography variant="body2" color="text.secondary"><GetTextUI formName={"companysetting"} label={"industrylabel"} /></Typography>
                                                        <IndustryList showButton={true} singleButtonIndustryId={item.industryId} />
                                                    </Stack>

                                                    <Stack direction="row" spacing={2}>
                                                        <Button variant="outlined" type="button" >  <GetTextUI formName={"usersetting"} label={"generateavatar"} /> </Button>
                                                        <Button variant="outlined" type="button" > <GetTextUI formName={"usersetting"} label={"uploadpicture"} /></Button>
                                                        <Button variant="contained" type="submit" > <GetTextUI formName={"default"} label={"update"} /> </Button>
                                                    </Stack>
                                                </Stack>


                                            </Grid>
                                        </Show>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={5}>
                                            <Show when={activeTab() === tabDetailsId}>
                                                <CardWithMinimize header={txtHeaderAddress() as string} type="stack" defaultOpen={true}>
                                                    <Stack margin={2} spacing={2}>

                                                        <Typography variant="body2" color="text.secondary"><GetTextUI formName={"companysetting"} label={"explainaddress"} /></Typography>

                                                        <Table size="small" >
                                                            <TableHead>
                                                                <TableRow >
                                                                    <TableCell>
                                                                        <Button
                                                                            size="small"
                                                                            variant="outlined"
                                                                            color="primary"
                                                                            startIcon={<AddIcon />}
                                                                        ><GetTextUI formName={"default"} label={"add"} /></Button>
                                                                    </TableCell>
                                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"contact"} label={"hq"} /></Typography></TableCell>
                                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"contact"} label={"country"} /></Typography></TableCell>
                                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"contact"} label={"address"} /></Typography></TableCell>
                                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"default"} label={"comment"} /></Typography></TableCell>

                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                <For each={company()?.companyAddress}>
                                                                    {(item, idx) => (
                                                                        <>
                                                                            <TableRow hover={true} onMouseEnter={() => setHoveredRow(idx())}
                                                                                onMouseLeave={() => setHoveredRow(-1)} >
                                                                                <TableCell>
                                                                                    <Show when={hoveredRow() === idx()}>
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            aria-label="Edit the address"
                                                                                        ><EditIcon /></IconButton>
                                                                                        <IconButton
                                                                                            size="small"
                                                                                            aria-label="Edit the address"

                                                                                        ><DeleteIcon /></IconButton>
                                                                                    </Show>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Switch id="isCustomer" inputProps={{ 'aria-label': 'controlled' }} onChange={onClickCustomer} checked={false} />
                                                                                </TableCell>
                                                                                <TableCell><Typography variant="body2" color="text.secondary">{item.country}</Typography></TableCell>
                                                                                <TableCell><Typography variant="body2" color="text.secondary">{item.street1} {item.street2} {item.postalCode} {item.city} {item.state}</Typography></TableCell>
                                                                                <TableCell><Typography variant="body2" color="text.secondary">{item.comment}</Typography></TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                    )}
                                                                </For>
                                                            </TableBody>
                                                            <TableFooter>
                                                            </TableFooter>
                                                        </Table>
                                                    </Stack>
                                                </CardWithMinimize>

                                            </Show>
                                        </Grid>

                                    </Grid>
                                    <Show when={activeTab() === tabViewsId}>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                            <CardWithMinimize header={txtHeaderViews() as string} type="stack" defaultOpen={true}>
                                                <Stack margin={2} spacing={2}>
                                                    {/* Views */}
                                                    <Stack direction="column" alignItems="left" spacing={0.5}  >
                                                        <Typography variant="subtitle1">{<GetTextUI formName={"onboard"} label={"viewaction"} />} </Typography>
                                                        <FormControlLabel
                                                            id="isCandidate"
                                                            control={<Switch id="isCandidate" inputProps={{ 'aria-label': 'controlled' }} onChange={onClickCandidate} checked={item.candidateView === "yes" ? false : true} />}
                                                            label={<GetTextUI formName={"default"} label={"candidate"} />}
                                                            sx={{ px: 2 }}
                                                        />
                                                        <FormControlLabel
                                                            id="isCustomer"
                                                            control={<Switch id="isCustomer" inputProps={{ 'aria-label': 'controlled' }} onChange={onClickCustomer} checked={item.customerView === "no" ? false : true} />}
                                                            label={<GetTextUI formName={"default"} label={"customer"} />}
                                                            sx={{ px: 2 }}
                                                        />
                                                        <FormControlLabel
                                                            id="isSupplier"
                                                            control={<Switch id="isSupplier" disabled />}
                                                            label={<GetTextUI formName={"default"} label={"supplier"} />}
                                                            sx={{ px: 2 }}
                                                        />
                                                        <FormControlLabel
                                                            id="isBroker"
                                                            control={<Switch id="isBroker" disabled />}
                                                            label={<GetTextUI formName={"default"} label={"broker"} />}
                                                            sx={{ px: 2 }}
                                                        />
                                                    </Stack>

                                                </Stack>
                                            </CardWithMinimize>

                                        </Grid>

                                    </Show>

                                </Box>
                            </>
                        )}
                    </For>
                    <Divider />


                    <Grid container   >
                        <Show when={activeTab() === tabMessageId}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CardWithMinimize header={txtHeaderMessageTemplates() as string} type="stack" defaultOpen={true}>
                                    <Stack margin={1} spacing={2}   >
                                        <Stack direction={isExtraSmall() ? "column" : "row"} spacing={2}>

                                            <ToggleButtonGroup
                                                color="primary"
                                                value={templateGroupId()}
                                                exclusive
                                                onChange={(event, newAlignment) => {
                                                    setTemplateGroupId(newAlignment);
                                                }}
                                            >
                                                <ToggleButton value="interview"> <GetTextUI formName={"default"} label={"interview"} /></ToggleButton>
                                                <ToggleButton value="feedback"><GetTextUI formName={"rating"} label={"feedback"} /> </ToggleButton>
                                                <ToggleButton value="proposal"><GetTextUI formName={"default"} label={"proposal"} /></ToggleButton>
                                                <ToggleButton value="contract"><GetTextUI formName={"default"} label={"contract"} /></ToggleButton>
                                            </ToggleButtonGroup>
                                            <ToggleButtonGroup
                                                color="primary"
                                                value={templateId()}
                                                exclusive
                                                onChange={(event, newAlignment) => {
                                                    setTemplateId(newAlignment);
                                                }}
                                            >
                                                <SolidSwitch>
                                                    <Match when={templateGroupId() === "interview"}>
                                                        <ToggleButton value="planinterview"><GetTextUI formName={"todopage"} label={"notacceptedinterviews"} /></ToggleButton>
                                                        <ToggleButton value="rescheduleinterview"><GetTextUI formName={"workflowpage"} label={"updateschedule"} /></ToggleButton>
                                                        <ToggleButton value="confirminterview"><GetTextUI formName={"todopage"} label={"confirminterviews"} /></ToggleButton>
                                                        <ToggleButton value="cancelinterview"><GetTextUI formName={"workflowpage"} label={"cancelinterview"} /></ToggleButton>
                                                    </Match>
                                                    <Match when={templateGroupId() === "feedback"}>
                                                        <ToggleButton value="positivefeedback"><GetTextUI formName={"workflowpage"} label={"positivefeedback"} /></ToggleButton>
                                                        <ToggleButton value="negativefeedback"><GetTextUI formName={"workflowpage"} label={"negativefeedback"} /></ToggleButton>
                                                    </Match>
                                                    <Match when={templateGroupId() === "proposal"}>
                                                        <ToggleButton value="proposal"><GetTextUI formName={"workflowpage"} label={"proposal"} /></ToggleButton>
                                                    </Match>
                                                    <Match when={templateGroupId() === "contract"}>
                                                        <ToggleButton value="sendcontract"><GetTextUI formName={"workflowpage"} label={"sendcontract"} /></ToggleButton>
                                                        <ToggleButton value="signcontract"><GetTextUI formName={"workflowpage"} label={"signcontract"} /></ToggleButton>
                                                        <ToggleButton value="askforextension"><GetTextUI formName={"workflowpage"} label={"askforextension"} /></ToggleButton>
                                                        <ToggleButton value="extendcontract"><GetTextUI formName={"workflowpage"} label={"extendcontract"} /></ToggleButton>
                                                        <ToggleButton value="terminatecontract"><GetTextUI formName={"workflowpage"} label={"terminatecontract"} /></ToggleButton>
                                                    </Match>
                                                </SolidSwitch>
                                            </ToggleButtonGroup>

                                        </Stack>


                                        <TxtEditor readonly={false} editorDocument={"<b> Template text" + "</b>" + ",</br>" + "</br>"} />

 

                                        <Button variant="outlined" startIcon={<SaveIcon />} type="submit"> <GetTextUI formName={"default"} label={"update"} /> </Button>
                                        

                                    </Stack>
                                </CardWithMinimize>
                            </Grid>
                        </Show>
                        <Show when={activeTab() === tabContractId}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CardWithMinimize header={txtHeaderContractSetting() as string} type="stack" defaultOpen={true}>
                                    <Stack margin={2} spacing={2}>
                                        <FormControlLabel
                                            id="proposalMandatory"
                                            control={<Switch id="isCandidate" inputProps={{ 'aria-label': 'controlled' }} onChange={onClickCandidate} checked={false} />}
                                            label={<GetTextUI formName={"companysetting"} label={"proposalbefore"} />}
                                            sx={{ px: 2 }}
                                        />
                                        <FormControlLabel
                                            id="coSignProposal"
                                            control={<Switch id="isCustomer" inputProps={{ 'aria-label': 'controlled' }} onChange={onClickCustomer} checked={true} />}
                                            label={<GetTextUI formName={"companysetting"} label={"cosignproposal"} />}
                                            sx={{ px: 2 }}
                                        />
                                        <FormControlLabel
                                            id="coSignContract"
                                            control={<Switch id="isSupplier" disabled />}
                                            label={<GetTextUI formName={"companysetting"} label={"cosigncontract"} />}
                                            sx={{ px: 2 }}
                                        />

                                    </Stack>
                                </CardWithMinimize>

                            </Grid>
                        </Show>
                        <Show when={activeTab() === tabTestId}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <CardWithMinimize header={txtHeaderTest() as string} type="stack" defaultOpen={true}>
                                    <Stack margin={2} spacing={2}>

                                        <FormControl>
                                            <FormLabel id="test-group-label"><GetTextUI formName={"companysetting"} label={"testformlabel"} /></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="test-site-label"
                                                defaultValue="disabled"
                                                name="test-site-group"
                                            >
                                                <FormControlLabel value="disabled" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"testdisabled"} />} />
                                                <FormControlLabel value="owner" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"testowner"} />} />
                                                <FormControlLabel value="all" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"testall"} />} />
                                            </RadioGroup>
                                        </FormControl>

                                        <Typography variant="body2" color="text.secondary">{<GetTextUI formName={"companysetting"} label={"testhelper"} />} </Typography>


                                    </Stack>

                                </CardWithMinimize>

                            </Grid>
                        </Show>
                    </Grid>
                    <Grid container>
                        <Show when={activeTab() === tabBillingId}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <CardWithMinimize header={txtHeaderBillingCustomer() as string} type="stack" defaultOpen={false}>
                                    <Stack margin={2} spacing={2}>

                                        <FieldTextValue
                                            id="billingEmail" label={<GetTextUI formName={"onboard"} label={"invoicecopy"} /> as string} textValue={""} width={230}
                                            valueOnChange={value => setState("billingEmail", value)}
                                        />

                                        <FieldTextValue
                                            id="invoiceReference" label={<GetTextUI formName={"companysetting"} label={"invoicereference"} /> as string} textValue={""} width={230}
                                            valueOnChange={value => setState("billingEmail", value)}
                                        />
                                        <Typography variant="body2" color="text.secondary"><GetTextUI formName={"companysetting"} label={"billingreference"} />  </Typography>


                                        <Button variant="outlined" type="button"><GetTextUI formName={"default"} label={"update"} /></Button>
                                    </Stack>
                                </CardWithMinimize>

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>

                                <CardWithMinimize header={txtHeaderBillingCandidate() as string} type="stack" defaultOpen={false}>
                                    <Stack margin={2} spacing={2}>

                                        <FormControl>
                                            <FormLabel id="paymentTerms"><GetTextUI formName={"companysetting"} label={"termsofpayment"} /></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="billing-header"
                                                defaultValue="30"
                                                name="PaymentTerms"
                                            >
                                                <FormControlLabel value="15" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"15days"} />} />
                                                <FormControlLabel value="30" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"30days"} />} />
                                                <FormControlLabel value="45" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"45days"} />} />
                                                <FormControlLabel value="60" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"60days"} />} />
                                            </RadioGroup>
                                        </FormControl>


                                        <FormControl>
                                            <FormLabel id="receiveinvoices"><GetTextUI formName={"companysetting"} label={"receiveinvoices"} /></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="receiveinvoices"
                                                defaultValue="eom"
                                                name="PaymentTerms"
                                            >
                                                <FormControlLabel value="weekly" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"weekly"} />} />
                                                <FormControlLabel value="eom" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"eom"} />} />
                                                <FormControlLabel value="fixed" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"fixed"} />} />
                                                <FormControlLabel value="completed" control={<Radio />} label={<GetTextUI formName={"companysetting"} label={"completed"} />} />
                                            </RadioGroup>
                                        </FormControl>
                                        <Button variant="outlined" type="button"><GetTextUI formName={"default"} label={"update"} /></Button>
                                    </Stack>
                                </CardWithMinimize>
                            </Grid>
                        </Show>
                        <Show when={activeTab() === tabBillingId}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <CardWithMinimize header={txtHeaderInvoices() as string} type="stack" defaultOpen={true}>
                                    <Stack margin={2} spacing={2}>

                                        <Typography variant="body2" color="text.secondary"><GetTextUI formName={"companysetting"} label={"receivedinvoices"} /></Typography>

                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"action"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"period"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"avaiable"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"description"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"amount"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"duedate"} /></Typography></TableCell>
                                                    <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"status"} /></Typography></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <For each={company()?.invoices}>
                                                    {(item, idx) => (
                                                        <>
                                                            <TableRow>
                                                                <TableCell>
                                                                    <Button
                                                                        color="inherit"
                                                                        size="small"
                                                                        variant="text"
                                                                        startIcon={<CloseListIcon />}
                                                                        aria-controls={anchorMenuEl() ? "long-menu-country1" : undefined}
                                                                        aria-haspopup="true"
                                                                        aria-expanded={anchorMenuEl() ? "true" : undefined}
                                                                        onClick={(event) => handleMenuOpen(event)}>

                                                                        <Menu
                                                                            id="long-menu-country1"
                                                                            anchorEl={anchorMenuEl()}
                                                                            open={Boolean(anchorMenuEl())}
                                                                            onClose={handleMenuClose}
                                                                        >
                                                                            <MenuItem><GetTextUI formName={"companysetting"} label={"downloadinvoice"} /></MenuItem>
                                                                            <MenuItem><GetTextUI formName={"companysetting"} label={"viewinvoice"} /></MenuItem>

                                                                        </Menu>
                                                                    </Button>
                                                                </TableCell>
                                                                <TableCell><Typography variant="body2" color="text.secondary">{item.billingMonth}</Typography></TableCell>
                                                                <TableCell><Typography variant="body2" color="text.secondary">{formatDateByCountry(item.invoiceUploaded)}</Typography></TableCell>
                                                                <TableCell><Typography variant="body2" color="text.secondary">{item.description}</Typography></TableCell>
                                                                <TableCell>
                                                                    <RateValueForTable displayType="Normal" value={Number(item?.invoiceAmount)} country={countryCode} currency={item?.currency} primaryRateModel={"hour"} />
                                                                </TableCell>
                                                                <TableCell><Typography variant="body2" color="text.secondary">{formatDateByCountry(item.invoiceDueDate)}</Typography></TableCell>
                                                                <TableCell>
                                                                    <SolidSwitch >
                                                                        <Match when={item.paid === "Yes"}>
                                                                            <Chip variant="outlined" label={<GetTextUI formName={"companysetting"} label={"paid"} />} color={"success"}></Chip>
                                                                        </Match>
                                                                        <Match when={item.paid === "No" && isInvoiceDueDatePassed(item.invoiceDueDate) === true}>
                                                                            <Chip variant="outlined" label={<GetTextUI formName={"companysetting"} label={"due"} />} color={"error"}></Chip>

                                                                        </Match>
                                                                        <Match when={item.paid === "No" && isInvoiceDueDatePassed(item.invoiceDueDate) === false}>
                                                                            <Chip variant="outlined" label={<GetTextUI formName={"companysetting"} label={"notdue"} />} color={"default"}></Chip>

                                                                        </Match>
                                                                    </SolidSwitch>
                                                                </TableCell>


                                                            </TableRow>
                                                        </>
                                                    )}
                                                </For>
                                            </TableBody>
                                        </Table>

                                    </Stack>
                                </CardWithMinimize>
                            </Grid>
                        </Show>
                    </Grid>

                    <Grid container   >
                        <Show when={activeTab() === tabGreylistId}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <CardWithMinimize header={txtHeaderGreylisted() as string} type="list" defaultOpen={true}>

                                    <Stack margin={2}>
                                        <Typography variant="body2" color="text.secondary"> <GetTextUI formName={"companysetting"} label={"greyintro"} /> </Typography>
                                    </Stack>
                                    {/* Candidates current assignment for the customer */}
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"action"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"default"} label={"candidate"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"hieredfor"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"greylisteddate"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"reportedby"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"reason"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"companysetting"} label={"nextaction"} /></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <For each={company()?.greylist}>
                                                {(item, idx) => (
                                                    <>
                                                        <TableRow>
                                                            <TableCell>
                                                                <Button
                                                                    color="inherit"
                                                                    size="small"
                                                                    variant="text"
                                                                    startIcon={<CloseListIcon />}
                                                                    aria-controls={anchorMenuEl() ? "long-menu-country1" : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={anchorMenuEl() ? "true" : undefined}
                                                                    onClick={(event) => handleMenuOpen(event)}>
                                                                    <Menu
                                                                        id="long-menu-country1"
                                                                        anchorEl={anchorMenuEl()}
                                                                        open={Boolean(anchorMenuEl())}
                                                                        onClose={handleMenuClose}
                                                                    >
                                                                        <MenuItem><GetTextUI formName={"companysetting"} label={"removefromlist"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"companysetting"} label={"reportviolation"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"companysetting"} label={"reportcvfraud"} /></MenuItem>
                                                                    </Menu>
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                                    <Avatar src={item.src}></Avatar>

                                                                    <A href={`/viewcv/${item.candidateId}`}>{item.candidateName}</A>

                                                                </Stack>

                                                            </TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">{item.jobName} | {item.jobRoleName}</Typography></TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">{formatDateByCountry(item.greylistDate)} {formatTimeByCountry(item.greylistDate)}</Typography></TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">  <ShowUserProfile userId={item.reportedByUserId} userName={item.reportedByUserName} />  </Typography></TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">{item.reasonText}</Typography></TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">{item.nextAction}</Typography></TableCell>

                                                        </TableRow>
                                                    </>
                                                )}
                                            </For>
                                        </TableBody>
                                    </Table>

                                </CardWithMinimize>
                            </Grid>
                        </Show>
                        <Show when={activeTab() === tabUsersId}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                                <CardWithMinimize header={txtHeaderUsers() as string} type="stack" defaultOpen={true}>

                                    {/* Candidates current assignment for the customer */}
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>
                                                    <Button
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        startIcon={<AddIcon />}
                                                    ><GetTextUI formName={"default"} label={"add"} /></Button>
                                                </TableCell>

                                                <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"userrole"} /></Typography></TableCell>

                                                <TableCell><Typography variant="body2"><GetTextUI formName={"contact"} label={"name"} /></Typography></TableCell>
                                                <TableCell><Typography variant="body2"><GetTextUI formName={"usersetting"} label={"userstatus"} /></Typography></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <For each={company()?.teams}>
                                                {(item, idx) => (
                                                    <>
                                                        <TableRow hover={true} onMouseEnter={() => setHoveredRow(idx())}
                                                            onMouseLeave={() => setHoveredRow(-1)}>
                                                            <TableCell>
                                                                <Show when={hoveredRow() === idx()}>

                                                                    <IconButton
                                                                        size="small"
                                                                        aria-label={<GetTextUI formName={"usersetting"} label={"askforretire"} /> as string}

                                                                    ><DeleteIcon /></IconButton>
                                                                </Show>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button
                                                                    color="inherit"
                                                                    size="small"
                                                                    variant="text"
                                                                    endIcon={<CloseListIcon />}
                                                                    aria-controls={anchorMenuEl() ? "long-menu-country1" : undefined}
                                                                    aria-haspopup="true"
                                                                    aria-expanded={anchorMenuEl() ? "true" : undefined}
                                                                    onClick={(event) => handleMenuOpen(event)}>
                                                                    {item.teamUserRole}
                                                                    <Menu
                                                                        id="long-menu-country1"
                                                                        anchorEl={anchorMenuEl()}
                                                                        open={Boolean(anchorMenuEl())}
                                                                        onClose={handleMenuClose}
                                                                    >
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantadm"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantbilling"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantcontract"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantrecruiter"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantmanager"} /></MenuItem>
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"grantuser"} /></MenuItem>
                                                                        <Divider />
                                                                        <MenuItem><GetTextUI formName={"usersetting"} label={"askforretire"} /></MenuItem>
                                                                    </Menu>
                                                                </Button>
                                                            </TableCell>
                                                            <TableCell>
                                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                                    <ShowUserProfile avatarSrc={item.src} userId={item.userId} userName={item.companyName} />
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell><Typography variant="body2" color="text.secondary">{item.teamUserStatus}</Typography></TableCell>
                                                        </TableRow>
                                                    </>
                                                )}
                                            </For>
                                        </TableBody>
                                    </Table>

                                </CardWithMinimize>
                            </Grid>
                        </Show>
                    </Grid>

                </Suspense>
            </Card >
            <br />
            <br />
        </>
    );
}
export { SettingCompanyComponent };